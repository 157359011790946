<template>
  <div>
    <el-row>
      <el-col>
        <p style="margin-bottom: 0">{{ this.inputNo }}</p>
        <el-result v-loading="doLoading" :icon="result" :title="resultTitle">
          <template slot="extra">
            <!--继续购物-->
            <el-button size="medium" type="primary" @click="byGoods">{{$t('button.continue.shopping')}}</el-button>
            <!--查看订单-->
            <el-button size="medium" @click="goOrderList">{{$t('paypal.viewOrder')}}</el-button>
          </template>
        </el-result>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {memberNotifyPay} from "@/api/rus/api";

export default {
  name: 'PayOnlineResult',
  data() {
    return {
      doLoading: false,
      result: 'error',
      resultTitle: this.$t('OrderGoodsFail').toString(),
      inputNo: ''
    }
  },
  created() {
    this.inputNo = this.$route.query.inputNo
    if (!this.inputNo) {
      this.$router.go(-1)
    } else {
      this.getResult(this.inputNo)
    }
  },
  destroyed() {
    this.inputNo = ''
  },
  methods: {
    byGoods() {
      this.$router.push({path: '/'})
    },
    goOrderList() {
      this.$router.push({path: '/order-list/index'})
    },
    getResult(inputNo) {
      this.doLoading = true
      memberNotifyPay({inputNo: inputNo})
          .then(res => {
            this.doLoading = false
            if (res.success) {
              this.result = 'success'
              this.resultTitle = '订货成功'
            } else {
              this.result = 'error'
              this.resultTitle = '订货失败'
            }
          })
          .catch(err => {
            this.doLoading = false
            console.error(err)
          })
    }
  }
}
</script>

<style>

</style>
